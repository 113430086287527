/* eslint-disable import/no-unresolved */
import { Box } from "@chakra-ui/react";
import Image from "next/image";
import Link from "next/link";
import React, { ReactElement } from "react";
import { generatePath } from "react-router";

import { H2 } from "@components/atoms/Text/H2";
import { P } from "@components/atoms/Text/P";
import { Carousel } from "@components/organisms/Carousel";
import CustomLink from "@components/organisms/Home/styles";
import { paths } from "@paths";

import AjeDesktop from "images/featured-designers/aje-desktop.png";
import Aje from "images/featured-designers/aje-mobile.png";
import AlemaisDesktop from "images/featured-designers/alemais-desktop.png";
import Alemais from "images/featured-designers/alemais-mobile.png";
// import AlexDesktop from "images/featured-designers/alex-perry-desktop.png";
// import Alex from "images/featured-designers/alex-perry-mobile.png";
import GingerDesktop from "images/featured-designers/ginger-and-smart-desktop.png";
import Ginger from "images/featured-designers/ginger-and-smart-mobile.png";
import KateDesktop from "images/featured-designers/kate-ford-desktop.png";
import Kate from "images/featured-designers/kate-ford-mobile.png";
import KitXDesktop from "images/featured-designers/kitx-desktop.png";
import KitX from "images/featured-designers/kitx-mobile.png";
import RachelDesktop from "images/featured-designers/rachel-gilbert-desktop.png";
import Rachel from "images/featured-designers/rachel-gilbert-mobile.png";
import BecDesktop from "images/featured-designers/rebecca-vallance-desktop.png";
import Bec from "images/featured-designers/rebecca-vallance-mobile.png";
import SirDesktop from "images/featured-designers/sir-the-label-desktop.png";
import Sir from "images/featured-designers/sir-the-label-mobile.png";
import ZimmermannDesktop from "images/featured-designers/zimmermann-desktop.png";
import Zimmermann from "images/featured-designers/zimmermann-mobile.png";

interface FeaturedDesignersProps {
  title: ReactElement | string | undefined;
  content: ReactElement | string | undefined;
  ctaText: ReactElement | string | undefined;
  isDesktop?: boolean;
}

const featuredDesigners = [
  {
    slug: "zimmermann-hire",
    name: "Zimmermann",
    imagePath: Zimmermann,
    imagePathDesktop: ZimmermannDesktop,
  },
  {
    slug: "aje-hire",
    name: "Aje",
    imagePath: Aje,
    imagePathDesktop: AjeDesktop,
  },
  {
    slug: "sir-the-label-hire",
    name: "Sir the Label",
    imagePath: Sir,
    imagePathDesktop: SirDesktop,
  },
  {
    slug: "alemais-hire",
    name: "Alemais",
    imagePath: Alemais,
    imagePathDesktop: AlemaisDesktop,
  },
  {
    slug: "rachel-gilbert-hire",
    name: "Rachel Gilbert",
    imagePath: Rachel,
    imagePathDesktop: RachelDesktop,
  },
  {
    slug: "kate-ford-hire",
    name: "Kate Ford",
    imagePath: Kate,
    imagePathDesktop: KateDesktop,
  },
  {
    slug: "rebecca-vallance-hire",
    name: "Rebecca Vallance",
    imagePath: Bec,
    imagePathDesktop: BecDesktop,
  },
  {
    slug: "ginger-smart-hire",
    name: "Ginger & Smart",
    imagePath: Ginger,
    imagePathDesktop: GingerDesktop,
  },
  {
    slug: "kitx-hire",
    name: "KitX",
    imagePath: KitX,
    imagePathDesktop: KitXDesktop,
  },
];

export const FeaturedDesigners: React.FC<FeaturedDesignersProps> = ({
  title,
  content,
  ctaText,
  isDesktop,
}) => (
  <Box w="100%" bgColor="tertiary" h="full">
    <Box
      padding={["6rem 0 6rem 0.93rem", null, null, "3.75rem 0 3.75rem 3.75rem"]}
      color="white"
      h="full"
      display="flex"
      flexDirection={["column", null, null, "row"]}
      justifyContent="space-between"
    >
      <Box
        w={["80%", null, null, "25%"]}
        justifyContent="center"
        display="flex"
        flexDirection="column"
        alignItems="start"
      >
        <H2>{title}</H2>
        <P mb={["1rem", null, null, "5rem"]} mt="1rem">
          {content}
        </P>
        <CustomLink
          href="/designers"
          style={{
            fontSize: isDesktop ? "1.2rem" : "1rem",
            padding: isDesktop ? "1rem 1.25rem" : "0.9rem 1rem",
            backgroundColor: "var(--chakra-colors-transparent) !important",
            borderColor: "white",
            marginTop: isDesktop ? "5rem" : "1rem",
          }}
        >
          {ctaText}
        </CustomLink>
      </Box>
      <Box
        w={["100%", null, null, "60%"]}
        position="relative"
        margin={["4.5rem 0 0 0", null, null, "1.87rem 0 5rem 5rem"]}
      >
        <Carousel
          spaceBetween={25}
          slides={featuredDesigners.map((featureDesigner) => (
            <Box cursor="pointer" flex={["0 0 160px", null, null, "0 0 322px"]}>
              <Link
                href={generatePath(paths.designer, {
                  slug: featureDesigner?.slug,
                })}
                passHref
              >
                <Box as="a">
                  <Image
                    src={
                      isDesktop
                        ? featureDesigner?.imagePathDesktop
                        : featureDesigner?.imagePath
                    }
                    alt={featureDesigner?.name}
                    width={isDesktop ? 322 : 160}
                    height={isDesktop ? 520 : 230}
                    objectFit="cover"
                  />
                </Box>
              </Link>
              <P fontWeight="bold" mt="0.4rem">
                {featureDesigner?.name}
              </P>
            </Box>
          ))}
        />
      </Box>
    </Box>
  </Box>
);
